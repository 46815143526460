<template lang="html">
  <section>
    <div>
      <div class="video-wrap">
        <video id="qrcode-reader-preview" playsinline="true" muted="true" autoplay />
      </div>
      <div class="error-message">
        {{ errorMessage }}
      </div>
      <div class="overlay" @click="clickOverlay">
        <slot />
      </div>
    </div>
  </section>
</template>

<script>
  /* eslint-disable vue/require-prop-types */

  import { Options, Reader } from 'eonbit-barcode-reader'

  export default {
    name: 'QrcodeReader',
    props: ['active', 'finish', 'allowDuplicate', 'readers'],
    data() {
      return {
        scanner: null,
        loadingSelectedCamera: false,
        errorMessage: '',
        lastResult: '',
        testMode: localStorage.eonBarcodeReaderTestMode === '1',
        overlayClicks: 0,
      }
    },
    watch: {
      active(val) {
        if (val) {
          this.start()
        } else {
          this.stop()
        }
      },
      finish(val) {
        if (val && this.scanner) {
          this.scanner.detach()
        }
      },
    },
    mounted() {
      const video = document.getElementById('qrcode-reader-preview')
      const options = new Options()
      // options.debug = true
      options.focus = {
        x: null, // ie. center
        y: 30,
        w: 250,
        h: 250,
      }
      if (this.readers && this.readers.length > 0) {
        options.readers = this.readers
      }
      this.scanner = new Reader(video, options)
      this.scanner.on('decode', (result) => {
        this.onDecode(result.data)
      })
      this.scanner
        .attach()
        .then(() => {
          this.$emit('ready')
        })
        .catch((err) => {
          this.$emit('ready', err)
        })
    },
    beforeDestroy() {
      if (this.scanner) {
        this.scanner.detach()
      }
    },
    methods: {
      onDecode(result) {
        if (!this.allowDuplicate && result === this.lastResult) {
          // console.log('QrcodeReader: ignore duplicate scan:', result)
          return
        }
        this.$emit('decode', result)
        this.lastResult = result
      },
      stop() {
        // console.log('QrcodeReader: stop scanner')
        this.scanner.stop()
        this.lastResult = ''
      },
      start() {
        // console.log('QrcodeReader: start scanner')
        this.lastResult = ''
        this.scanner.start()
      },
      clickOverlay() {
        this.overlayClicks++
        if (this.overlayClicks > 4) {
          this.testMode = !this.testMode
          this.overlayClicks = 0
          localStorage.eonBarcodeReaderTestMode = this.testMode ? '1' : ''
        }
      },
    },
  }
</script>

<style lang="sass" scoped>
  video
    width: 100%

  .video-wrap
    max-height: 100vh
    overflow-y: hidden

  section
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1100
    background: black

  .overlay
    position: fixed
    bottom: 50px
    left: 20px
    right: 20px
    height: 150px
    vertical-align: middle
    background: black
    opacity: 0.7

  .error-message
    position: fixed
    bottom: 50%
    left: 10px
    right: 10px
    text-align: center
    color: red
    font-weight: bold
    font-size: 1.2rem

  .test
    z-index: 10000
    position: fixed
    top: 5px
    width: 100%
    text-align: center

    a
      padding-right: 10px
</style>
