export function toCamelCase(data) {
  return data.replace(/(_[a-z])/g, function ($1) {
    return $1.toUpperCase().replace('_', '')
  })
}

export function toSnakeCase(data) {
  return data.replace(/([A-Z])/g, function ($1) {
    return '_' + $1.toLowerCase()
  })
}
